@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  box-sizing: "border-box";
}

body::-webkit-scrollbar {
  width: 15px; /* Adjust width as needed */
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #f2f2f2 inset !important;
  -webkit-text-fill-color: #000 !important;
  border-radius: 100px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  background-color: transparent !important;
}
.notification-container {
  z-index: 40; /* Lower than the suggestion layer */
}
.mainindex {
  z-index: 11000;
}

.suggestion-layer {
  z-index: 60; /* Higher than the notification component */
}

body::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light gray background */
}
.ant-input:focus {
  box-shadow: none !important;
  border-color: #d9d9d9 !important; /* or the color you want */
}
body::-webkit-scrollbar-thumb {
  background: #ccc; /* Light gray thumb */
  border-radius: 10px; /* Capsule shape */
}

.ant-input:focus,
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #d9d9d9 !important; /* Change to your desired border color */
  box-shadow: none !important;
}
.ant-modal-content {
  padding: 6.5px !important;
  width: 100% !important;
}
.custom-spin {
  color: orange !important; /* Change color as needed */
}
.ant-spin-dot-item {
  background-color: orange !important;
}
.ant-modal-content {
  border-radius: 30px !important;
}
.carousel .control-dots .dot {
  background: rgb(27, 26, 26);
  box-shadow: none; /* Removes shadow from the dots */
}

.carousel .control-dots .dot.selected {
  background: rgb(244, 140, 13);
  box-shadow: none; /* Removes shadow from the selected dot */
}

/* Custom styles for the arrows */
.carousel .control-arrow {
  box-shadow: none; /* Removes shadow from the arrows */
}
.ant-modal-content {
  border-radius: 0px !important;
}
@media (max-width: 690px) {
  .sidebar-complete-hide {
    display: none;
  }
}
@media (max-width: 1286px) {
  .hidefrommed {
    display: none;
  }
}
@media (max-width: 900px) {
  .hidenotification {
    display: none;
  }
}
.showcenterlogo {
  display: block;
}

/* Hide the logo on screens wider than 900px */
@media (min-width: 901px) {
  .showcenterlogo {
    display: none;
  }
}
.paddingy {
  padding-top: 2.5rem;
  padding-bottom: 5.5rem;
}

@media (min-width: 901px) {
  .paddingy {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}
@media (min-width: 1287px) {
  .hidelarge {
    display: none;
  }
}

@media (max-width: 1286px) {
  .medwidth {
    width: 5rem;
  }
}
@media (min-width: 1287px) {
  .largewidth {
    width: 18rem;
  }
}

@media (max-width: 1286px) {
  .medstart {
    display: flex;
    justify-content: start;
    align-items: center;
  }
}
@media (min-width: 1287px) {
  .largecenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.bottom-navigation {
  display: none;
}

@media (max-width: 690px) {
  .bottom-navigation {
    display: block;
    /* position: fixed; */
  }
}
@media (max-width: 690px) {
  .topfixed {
    /* display: block; */
    position: fixed;
  }
}
@media (max-width: 690px) {
  .sticky-top {
    position: fixed;
    top: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    z-index: 1000; /* Adjust as needed to ensure it stays on top of other content */
    margin-bottom: 120px;
  }
}
@media (max-width: 690px) {
  .mobile-top {
    margin-top: 40px;
  }
}
@media (max-width: 690px) {
  .heightchat {
    height: 92vh;
  }
}

.exploreDiv {
  display: none;
}

/* Show the div when the screen width is 900px or less (small screen) */
@media (max-width: 900px) {
  .exploreDiv {
    display: block;
  }
}

/* Show the div when the screen width is 600px or less (extra small screen) */
@media (max-width: 600px) {
  .exploreDiv {
    display: block;
    /* You can apply specific styles for even smaller screens if needed */
  }
}
@media (max-width: 998px) {
  .hide-up-to-998px {
    display: none;
  }
}

/* Reveal element for widths above 998px */
@media (min-width: 999px) {
  .hide-up-to-998px {
    display: block; /* or other display value as needed, e.g., flex */
  }
}
.custom-timepicker {
  border-color: transparent; /* Remove default border color */
  border-radius: 9999px; /* Keep it rounded */
}

/* Override the focus outline */
.custom-timepicker:focus {
  border-color: transparent; /* Remove blue border */
  box-shadow: none; /* Remove the shadow effect */
}

/* Style the dropdown */
.custom-dropdown .ant-picker-dropdown {
  border-radius: 8px; /* Customize dropdown border radius if needed */
}

/* Change the OK button color to gray */
.custom-dropdown .ant-btn-primary {
  background-color: gray !important; /* Set OK button color */
  border-color: gray !important; /* Set OK button border color */
  color: white !important; /* Set OK button text color */
}

/* Change hover state for OK button */
.custom-dropdown .ant-btn-primary:hover {
  background-color: darkgray !important; /* Set hover color */
}
.ant-picker input {
  border: none !important; /* Removes the border */
  outline: none !important; /* Removes the outline */
}
/* Custom styles to override Ant Design picker component */
/* Custom styles to override Ant Design picker component on focus */
:where(.css-dev-only-do-not-override-1r287do).ant-picker-outlined:focus,
:where(.css-dev-only-do-not-override-1r287do).ant-picker-outlined:focus-within {
  border-color: transparent !important; /* Removes border color on focus */
  box-shadow: none !important; /* Removes shadow effect on focus */
}
/* .ant-picker-ok {
  display: none !important;
} */
.css-jupps9-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 50px !important;
}
.css-umzx0k-MuiClock-pin {
  background-color: #404040 !important;
}
.css-pncb2q-MuiClockPointer-root {
  background-color: #404040 !important;
}
.css-f53ilk-MuiClockPointer-thumb {
  background-color: #404040 !important;
  border: 16px solid #404040 !important;
}
.react-tel-input .selected-flag {
  background-color: transparent !important;
  border: 50px !important;
}
.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
  margin-left: 10px;
}
